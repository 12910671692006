import { createTheme } from "@material-ui/core/styles";

export const grisClair = "#f5f6fa";
export const grisMoyen = "#a1a1a1";
export const primaryRed = "#ff3f33";
export const primaryBlack = "#1b202d";
export const success = "#72de85";
// export const gradientRed = 'linear-gradient(83deg, #ff5951 1%, #f02c23 98%)';
export const gradientRed = "linear-gradient(62deg, #ff5951 1%, #f02c23 98%)";
export const gradientGris = "linear-gradient(43deg, #f7f6f6 11%, #d4d5d7 99%)";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#222",
    },
    secondary: {
      main: "#ff3f33",
    },
    text: {
      hint: "#ff4137",
    },
  },
  typography: {
    fontFamily: ["Mulish, sans-serif"].join(","),
    h1: {
      fontSize: 42,
      color: primaryRed,
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600,
    },
    h2: {
      fontSize: 33,
      color: "white",
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600,
    },
    h3: {
      fontSize: 23,
      color: primaryRed,
      fontFamily: "Poppins, sans-serif",
      fontWeight: 700,
    },
    h4: {
      fontSize: 15,
      color: primaryBlack,
      fontFamily: "Muli, sans-serif",
      fontWeight: 500,
      lineHeight: "24px",
    },
    body1: { fontSize: 14, color: "black" },
    body2: { color: grisMoyen },
    button: {
      fontSize: 17,
      fontFamily: "Poppins, sans-serif",
      fontWeight: 500,
    },
  },
});

export default theme;
