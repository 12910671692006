import { Theme } from "@material-ui/core";
import { primaryRed } from "../theme";
const styles = (theme: Theme) =>
  ({
    containerNotFound: {
      display: "flex",
      margin: "auto",
      padding: "12px 300px 12px 148px",
      marginTop: "74px",
      "& h4": {
        fontWeight: "400",
        fontFamily: "Poppins, sans-serif",
        lineHeight: 1.235,
      },
      "& h3": {
        fontWeight: "400",
        padding: "24px 0",
      },
      "& h2": {
        color: primaryRed,
        paddingTop: 16,
        paddingBottom: 16,
      },
      "& button": {
        marginTop: 24,
      },
    },
    container: {
      padding: "24px 0",
      flex: 1,
    },
    illu: {
      flex: 1,
      width: "50%",
    },
    title: {
      "& > h2": {
        fontWeight: "300",
      },
    },
  } as any);

export default styles;
