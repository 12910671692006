import { Typography } from "@material-ui/core";
import { Card, Footer, Header } from "bc-front-sagacomponents";
import React, { useEffect } from "react";

const DonneesPersonnellesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header lienAccueil={() => {}} hideConnectionBtn />
      <section>
        <div>
          <br />
          <Typography variant="h1">Données Personnelles</Typography>
          <br />
          <Card>
            <p>
              Dans le cadre des services d’accompagnement et de conseil en
              assurances que nous vous apportons, ainsi que pour l’exécution de
              vos contrats d’assurances, des données personnelles seront
              collectées et traitées par SAGA SERVICE PLUS – 46 bis rue des
              hauts pavés 44000 NANTES. <br /> <br />
              Plus précisément, le traitement de ces données personnelles nous
              permettra d’analyser efficacement le dossier de candidature, de
              mettre en œuvre la gestion et l’exécution de vos contrats
              d’assurances ainsi que la gestion de vos sinistres et de vos
              indemnisations. Vos données pourront aussi être utilisées pour
              nous permettre de respecter nos obligations de vigilance (lutte
              contre la fraude, le blanchiment de capitaux et le financement du
              terrorisme). Elles pourront faire l’objet d’analyses et de
              statistiques afin d’améliorer notre accompagnement, nos services
              et nos produits et ainsi optimiser la relation client.
              <br /> <br />
              Ces traitements sont fondés sur l’exécution du contrat qui nous
              lie, le respect de nos obligations légales de connaissance de nos
              clients et de vigilance, ainsi que sur l’intérêt légitime du
              courtier en assurances (bases juridiques).
              <br /> <br />
              Les données requises doivent être impérativement renseignées afin
              que nous puissions gérer vos demandes de souscription et
              l’exécution de vos contrats. En cas de fausses déclarations ou
              d’omissions, les conséquences peuvent être le refus de vos
              souscriptions, la nullité du contrat souscrit ou la réduction des
              garanties.
              <br /> <br />
              Les données seront conservées tant que le contrat est actif
              (éventuellement augmentée de la durée de prescription liée à
              toutes actions découlant de la souscription). En cas de sinistre
              ou de litige, la durée de conservation est prorogée jusqu’à
              l’écoulement des prescriptions desdites actions. Lorsqu’une
              obligation légale nous impose de devoir disposer des données
              personnelles, elles seront conservées aussi longtemps que cette
              obligation légale s’impose à nous. En l’absence de conclusion d’un
              contrat, les données personnelles sont conservées pour une durée
              de 3 ans depuis le dernier contact.
              <br /> <br />
              Dans le cadre susmentionné, les données pourront être communiquées
              aux autres sociétés Bessé, aux assureurs et aux experts, à nos
              sous-traitants (comme nos prestataires informatiques), aux
              autorités publiques et à nos partenaires intermédiaires en
              assurances.
              <br /> <br />
              En outre, certaines données personnelles pourront être utilisées,
              mais avec l’accord exprès du titulaire, par nos partenaires
              commerciaux afin de vous proposer d’autres services et offres
              visant à mieux vous accompagner.
              <br /> <br />
              Conformément à la loi, chaque titulaire peut demander l’accès, la
              rectification, l’effacement ou la portabilité de ses données,
              définir des consignes relatives à leur sort après son décès,
              choisir d’en limiter l’usage ou s’opposer à leur traitement en
              écrivant par courriel à notre Délégué à la Protection des Données
              : <a href="mailto:dpo@besse.fr">dpo@besse.fr</a>. Si le titulaire
              a donné une autorisation expresse à l’utilisation de certaines de
              ses données, il peut de la même manière la retirer à tout moment
              sous réserve qu’il ne s’agisse pas d’informations indispensables à
              l’application de son contrat. En cas de réclamation, le titulaire
              peut choisir de saisir la CNIL.
              <br /> <br />
              Le traitement des données personnelles est effectué de manière
              sécurisée dans notre système d’information. Nous veillons à
              sensibiliser et former régulièrement nos collaborateurs à la bonne
              préservation des données personnelles.
              <br /> <br />
            </p>
          </Card>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DonneesPersonnellesPage;
