import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DonneesPersonnellesPage from "./DonneesPersonnelles";
import HomePage from "./HomePage";
import MentionsLegalesPage from "./MentionsLegales";
import NotFoundPage from "./NotFoundPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/mentions-legales" element={<MentionsLegalesPage />} />
        <Route
          path="/donnees-personnelles"
          element={<DonneesPersonnellesPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
