import { Typography, withStyles } from "@material-ui/core";
import { Button, Header } from "bc-front-sagacomponents";
import imgFond from "../images/illustration-droite.svg";
import styles from "./style";
import illustration from "../images/illustration-notfound.svg";
import { useNavigate } from "react-router-dom";

interface Props {
  classes?: any;
}

const NotFoundPage = (props: Props) => {
  const { classes } = props;
  const navigate = useNavigate();
  return (
    <div className="App">
      <Header lienAccueil={() => {}} hideConnectionBtn />

      <div className={classes.containerNotFound}>
        <div className={classes.container}>
          <div className={classes.title}>
            <Typography variant="h2" color="secondary">
              Cette page n'existe <strong>pas ou plus</strong>
            </Typography>
            <Typography variant="h4">
              Nous vous prions de nous excuser pour la gêne occasionnée
              <br /> Nous vous invitions à revenir à la page d'accueil de notre
              site.
            </Typography>
            <Button
              title="Retour à l'accueil"
              onClick={() => navigate("/")}
              variant="contained"
              color="primary"
            />
            <img
              className="fond"
              width="auto"
              height="350"
              src={imgFond}
              alt="fond"
            />
          </div>
        </div>
        <img className={classes.illu} src={illustration} alt="pageNotFound" />
      </div>
    </div>
  );
};

export default withStyles(styles)(NotFoundPage);
