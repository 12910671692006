import { Typography } from "@material-ui/core";
import { Card, Footer, Header } from "bc-front-sagacomponents";
import React, { useEffect } from "react";

const MentionsLegales = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header lienAccueil={() => { }} hideConnectionBtn />
      <section>
        <br />
        <Typography variant="h1">
          <strong>Mentions légales</strong>
        </Typography>
        <br />
        <Card>
          <Typography variant="h3">Identification</Typography>
          <p>
            Ce site est édité par SAGA SERVICE PLUS (ci-après « <b>SAGA</b> »),
            société par actions simplifiée au capital de 133 950 euros,
            immatriculée au RCS de Nantes sous le n°350 751 129, dont le siège
            social est situé 46 bis rue des Hauts Pavés 44000 NANTES.
            <br />
            <br />
            Conseil et courtier en assurances (exerçant conformément à l’article
            L521-2-1°b) du Code des assurances)- La liste des fournisseurs
            actifs est disponible au point 10 ci-dessous. Immatriculation à
            l’Organisme pour le Registre des Intermédiaires en Assurances
            (ORIAS) sous le numéro 07 019 221 –{" "}
            <a href="www.orias.fr" target="_blank">
              www.orias.fr
            </a>
            . <b>SAGA</b> est soumise au contrôle de l’Autorité de Contrôle
            Prudentiel et de Résolution (ACPR), 4 place de Budapest 75009 Paris.{" "}
            <br />
            <br />
            Directeur de la publication : Monsieur Pierre BESSE.
            <br />
            <br />
            Responsable de la rédaction et hébergeur : GIE INFO 2, 46 bis rue
            des Hauts Pavés - 44000 NANTES.
            <br />
            <br />
            Toute réclamation ou demande sur les procédures de médiation peuvent être adressées par écrit à l’adresse suivante :<br /><br />
            Service Réclamation / Info médiation - 46 bis rue des Hauts Pavés 44000 Nantes.<br /><br />
            Vous recevrez un accusé de réception sous 10 jours maximum et une réponse dans un délai maximum de 2 mois à compter de l’envoi de votre réclamation.<br /><br />
            En tout état de cause, deux (2) mois après l’envoi de votre première réclamation écrite, quel que soit l’interlocuteur ou le service auprès duquel elle
            a été formulée et qu’il y ait été ou non répondu, vous avez la possibilité de saisir le Médiateur de l'Assurance : par courrier à l’adresse suivante :
            La Médiation de L’assurance - TSA 50110 - 75441 PARIS Cedex 09, ou en vous rendant sur le site suivant :
            <a href="http://www.mediation-assurance.org/" target="_blank">La médiation de l'assurance - Accueil</a>.
            <br />
            <br />
          </p>
          <Typography variant="h3">Conditions d'utilisation</Typography>
          Nous vous recommandons de lire attentivement les présentes conditions
          d’utilisation ("<b>les Conditions</b>") et de les consulter de manière
          régulière car elles sont susceptibles d’être modifiées.
          <br />
          <br />
          En accédant à ce site, vous acceptez d’être lié(e) par ces Conditions,
          et notamment par notre politique en matière de gestion des données
          personnelles. Si vous n’acceptez pas d’être lié(e) par ces Conditions,
          vous ne devez pas accéder au présent site ni en consulter les pages.
          <br />
          <ul>
            <li>
              Bien que <b>SAGA</b> ait pris soin de s’assurer que les
              informations présentées sur le site soient correctes au moment de
              leur mise en ligne, <b>SAGA</b> ne garantit aucunement leur
              exactitude et leur exhaustivité et ne saurait en conséquence être
              tenu responsable d’éventuelles erreurs, inexactitudes ou omissions
              les concernant. Le présent site est mis à votre disposition de
              manière gracieuse, sans aucune garantie de quelque nature que ce
              soit quant à son contenu. <b>SAGA</b> ne pourra être tenu
              responsable de tout dommage lié au contenu, à l’utilisation du
              site ou à tout message qui pourrait être envoyé via le site.
              <br />
              La responsabilité de <b>SAGA</b> ne pourra pas non plus être
              recherchée en cas de panne, inaccessibilité, défaillance, bug ou
              difficulté de fonctionnement de tout ou partie du site.
            </li>
            <br />
            <li>
              <b>SAGA</b> est le propriétaire exclusif ou détient les droits
              d’usage de tous les éléments constituant le site et de tous les
              droits de propriété intellectuelle qui y sont attachés, ce qui
              inclut sans limitation : les marques, les logos, les noms de
              domaine, les photographies, les illustrations, les images animées
              ou non, les films, les graphismes, les commentaires, les textes,
              les sons, les applications informatiques, les logiciels.
              L’ensemble de ces éléments est protégé par la loi.
              <br />
              Toute représentation, utilisation, reproduction, exploitation,
              adaptation, modification, publication ou distribution de tout ou
              partie du site et des droits qui y sont attachés, faites sans le
              consentement préalable, exprès et écrit de <b>SAGA</b>, est
              illicite et constituera une contrefaçon quel que soient les
              supports et les moyens utilisés.
              <br />
              Aucun manquement ou retard de <b>SAGA</b> à engager une quelconque
              procédure pour faire respecter ses droits, ne saurait être
              considéré ou constituer une renonciation ou une renonciation
              ultérieure à exercer un tel droit.
            </li>
            <br />
            <li>
              Il est strictement interdit de rendre tout ou partie de ce site
              disponible sur un autre site, que ce soit par l’utilisation d’un
              hyperlien ou de toute autre manière, à moins d’y avoir été
              expressément autorisé au préalable et par écrit par <b>SAGA</b>.
              Pour obtenir une autorisation, vous devez envoyer soit un courriel
              soit un courrier postal à <b>SAGA</b> aux coordonnées mentionnées
              ci-dessous au point 9. avec les informations suivantes :
              <ul>
                <li>
                  votre nom ou le nom du contact technique ayant la
                  responsabilité des liens,
                </li>
                <li>le nom de votre société,</li>
                <li>
                  votre adresse mèl et votre numéro de téléphone ou l’adresse
                  mèl et le numéro de téléphone du contact technique responsable
                  des liens,
                </li>
                <li>
                  l’adresse ou les adresses des sites sur lesquels les liens
                  vers la page d’accueil figureront.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Ce site peut contenir des liens hypertextes vers d’autres sites,
              mis en place avec l’autorisation de <b>SAGA</b>. Néanmoins,{" "}
              <b>SAGA</b> ne contrôle en aucune manière le contenu de ces sites
              et n’assumera en conséquence aucune responsabilité liée à ces
              sites.
            </li>
            <br />
            <li>
              <b>SAGA</b> se réserve le droit à tout moment et sans avis
              préalable d'améliorer, de modifier, de suspendre ou de cesser de
              manière permanente tout ou partie du site et de restreindre ou
              d’interdire l’accès à ce dernier.
            </li>
            <br />
            <li>
              Les informations contenues dans le site ne constituent en aucun
              cas des conseils, une offre commerciale, une offre à contracter ou
              des avis juridiques. Pour des raisons de sécurité, il est prudent
              de considérer qu'une demande de garantie d'assurance, de prise
              d'effet de garantie, ou de déclaration de sinistre, n'aura été
              prise en compte qu'à partir du moment où elle aura donné lieu à
              une réponse personnalisée du destinataire (à l'exclusion donc de
              tout procédé automatique d'accusé de réception ou de lecture). A
              défaut, votre message devra être considéré comme non reçu.
            </li>
            <br />
            <li>
              Les présentes Conditions peuvent être modifiées à tout moment par{" "}
              <b>SAGA</b>. L’utilisation de ce site à la suite de ces
              changements emporte votre acceptation de ces derniers.
            </li>
            <br />
            <li>
              Pour toute question ou demande que vous pourriez avoir en relation
              avec le présent site, les présentes Conditions ou sur <b>SAGA</b>,
              vous pouvez nous écrire par courriel à l’adresse suivante :{" "}
              <a href="mailto:contact@sagaserviceplus.fr">
                contact@sagaserviceplus.fr
              </a>{" "}
              ou par courrier postal à l’adresse suivante : SAGA, 46 bis rue des
              Hauts Pavés – BP 52 216 - 44 022 Nantes Cedex 01.
            </li>
            <br />
            <li>
              Les présentes Conditions et le contenu du présent site sont régis
              par la loi française dont les tribunaux ont compétence exclusive
              dans le règlement de tout litige. En cas de litige concernant un
              consommateur non professionnel, conformément à L 133-4 du Code de
              la consommation, vous disposez de la faculté de saisir un
              médiateur. Liste des médiateurs disponible sur simple demande à
              l’adresse indiquée au point 9.
            </li>
            <br />
            <li>
              Liste des fournisseurs actifs : Europ Assistance ; Mondial
              Assistance (y.c Fragonard SA et AWP P&C SA)
            </li>
          </ul>{" "}
        </Card>
        <br />
        <br />
        <br />
      </section>

      <Footer />
    </div>
  );
};

export default MentionsLegales;
