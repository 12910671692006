import { Typography } from "@material-ui/core";
import { Button, Card, Footer, Header } from "bc-front-sagacomponents";
import React from "react";
import { getUrlPart, getUrlPro } from "../Config";
import history from "../history";
import imgFond from "../images/illustration-droite.svg";
import "../style.css";

function App() {
  const urlPro = getUrlPro();
  const urlPart = getUrlPart();
  return (
    <div className="App">
      <Header lienAccueil={() => {}} hideConnectionBtn />
      <section>
        <Typography variant="h1" className="titleHome">
          <strong>La route </strong> plus sereine...
        </Typography>
        <Typography variant="h4">
          Avec votre carte franchise de vrais avantages pour votre véhicule,{" "}
          <br />
          pour vous et votre famille... et pour votre budget !
        </Typography>
        <div className="container-images">
          <Card className="container-image">
            <Card red className="red-title">
              Vous êtes
              <br /> <Typography variant="h2"> distributeur ?</Typography>
            </Card>
            <div
              className="image image-pro"
              onClick={() => urlPro && history.push(urlPro)}
            ></div>
            <div className="container-btn">
              {" "}
              <Button
                className="btnSel"
                variant="contained"
                color="primary"
                title="Accéder à Saga en ligne"
                onClick={() => urlPro && history.push(urlPro)}
              />
            </div>
          </Card>
          <Card className="container-image">
            <Card red className="red-title">
              Vous êtes
              <br /> <Typography variant="h2"> adhérent ?</Typography>
            </Card>
            <div
              className="image image-part"
              onClick={() => urlPart && history.push(urlPart)}
            ></div>
            <div className="container-btn">
              {" "}
              <Button
                className="btnSel"
                variant="contained"
                color="primary"
                title="Accéder à Saga en ligne"
                onClick={() => urlPart && history.push(urlPart)}
              />
            </div>
          </Card>
        </div>
      </section>
      <img
        className="fond"
        width="auto"
        height="350"
        src={imgFond}
        alt="fond"
      />
      <Footer />
    </div>
  );
}

export default App;
